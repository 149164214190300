/* eslint-disable max-statements */
/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
/* eslint-disable-next-line max-statements */
export interface CheckValidity{
  isValid: boolean;
  error: object
}
//TODO: refactor to many small functions
const checkValidity = (value: any, rules = null): CheckValidity => {
  let isValid = true;
  const error = [];

  if (!rules){
    return { isValid, error };
  }

  if (rules.required === true && (!value || value === '')) {
    error.push('* Esse campo é obrigatório!');
    isValid = false;
  }

  if (rules.minLengthNotRequired && value && value.length < rules.minLengthNotRequired) {
    error.push(`* Esse campo tem que ter no mínimo ${rules.minLengthNotRequired} caracteres!`);
    isValid = false;
  }

  if (rules.minLength && value.length < rules.minLength) {
    error.push(`* Esse campo tem que ter no mínimo ${rules.minLength} caracteres!`);
    isValid = false;
  }

  if (rules.maxLength && value.length > rules.maxLength) {
    error.push(`* Esse campo tem que ter no maximo ${rules.maxLength} caracteres!`);
    isValid = false;
  }

  if (rules.maxValue && +value > rules.maxValue) {
    error.push(`* Esse campo admite o valor máximo ${rules.maxLength}!`);
    isValid = false;
  }

  if (rules.minValue && +value < rules.minValue) {
    error.push(`* Esse campo admite o valor mínimo ${rules.maxLength}!`);
    isValid = false;
  }

  if (rules.hasLastName) {
    const name = value.split(' ');
    if (name.length < 2 ||  (name.length >= 2 && name[1].length < 1)) {
      error.push(`* Esse campo admite o valor mínimo ${rules.maxLength}!`);
      isValid = false;
    }
  }

  let pattern = /\d{2}\/\d{2}\/\d{4}/;
  if (rules.data && !pattern.test(value) && (rules.required === true && (!value && value.trim() === ''))){
    error.push('* Esse data não é valido!');
    isValid = false;
  }

  pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

  if (rules.isEmail && (value.trim() === '' ||  !pattern.test(value)) ) {
    error.push('* Esse e-mail não é valido!');
    isValid = false;
  }

  pattern=/^[0-9]+$/;

  if (rules.isNumber && !pattern.test(value)){
    error.push('* Insira código de verificação numérico enviado para o seu e-mail.');
    isValid = false;
  }

  const defaultPhone = /^\(\d{2}\) \d{4}-\d{5}$/;
  console.log(value);

  if (rules.isPhone && !defaultPhone.test(value)) {
    error.push('* Esse telefone não é valido!');
    isValid = false;
  }

  return { isValid, error };
};

export default checkValidity;
