import SentryTagsInterface from '@interface/sentry/sentryTagsInterface';
import * as Sentry from '@sentry/nextjs';
import { useCallback, useState } from 'react';

function useSessionStorage(key, initialValue: any = '') {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined') {
      return initialValue;
    }
    try {
      const item = window.sessionStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const setValue = useCallback((value) => {
    try {
      const prevState = (value) => {
        if (typeof value === 'function') {
          const auxValue = value();
          return auxValue;
        }
        return value;
      };

      value = prevState(value);

      setState(value);

      window.sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      const tags: SentryTagsInterface = {
        urlPath: 'useSessionStorage',
        [key]: value
      };

      Sentry.captureException(error,
        {
          tags
        });
    }
  }, [key]);

  return [state, setValue];
}

export default useSessionStorage;
