/* eslint-disable no-use-before-define */
/* eslint-disable max-len */
const maskInput = (value, mask) => {
  //adiciona mascara de cnpj
  const cnpj = (cnpj) => {
    cnpj = cnpj.replace(/\D/g, '');
    cnpj = cnpj.replace(/^(\d{2})(.{1,})/g, '$1.$2');
    cnpj = cnpj.replace(/(\d{3})(.{1,})/g, '$1.$2');
    cnpj = cnpj.replace(/(.{10})(.{1,})/g, '$1/$2');
    cnpj = cnpj.replace(/(.{15})(.{1,})/g, '$1-$2');

    return cnpj;
  };

  //adiciona mascara de cep
  const cep = (cep) => {
    cep = cep.toString();
    cep = cep.replace(/\D/g, '');             //Remove tudo o que não é dígito
    cep = cep.replace(/^(\d{2})(\d)/g, '$1.$2'); //Coloca parênteses em volta dos dois primeiros dígitos
    cep = cep.replace(/(\d{3})(\d)/, '$1-$2');
    cep = cep.replace(/(\d{2})(\d{3})$/, '$1$2');

    return cep;
  };

  //adiciona mascara de data
  const data = (data) => {
    data = data.toString();
    data = data.replace(/\D/g, '');             //Remove tudo o que não é dígito
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d)/, '$1/$2');
    data = data.replace(/(\d{2})(\d{2})$/, '$1$2');

    return data;
  };

  // adiciona mascara ao telefone
  const phone = (tel) => {
    tel = tel.toString();
    tel = tel.replace(/\D/g,'');             //Remove tudo o que não é dígito
    tel = tel.replace(/^(\d{2})(\d)/g,'($1) $2'); //Coloca parênteses em volta dos dois primeiros dígitos
    tel = tel.replace(/(\d)(\d{4})$/,'$1-$2');    //Coloca hífen entre o quarto e o quinto dígitos

    return tel;
  };

  //adiciona mascara ao CPF
  const cpf = (cpf) => {
    cpf = onlyNumber(cpf);
    cpf = cpf.toString();
    cpf = cpf.replace(/\D/g, '');
    cpf = cpf.replace(/(.{3})(.{1,})/g, '$1.$2');
    cpf = cpf.replace(/(.{7})(.{1,})/g, '$1.$2');
    cpf = cpf.replace(/(.{11})(.{1,})/g, '$1-$2');

    return cpf;
  };

  const rg = (rg) => {
    rg = onlyNumber(rg);
    rg = rg.replace(/\D/g, '');
    rg = rg.replace(/^(\d{2})(\d)/g, '$1.$2');
    rg = rg.replace(/(\d{3})(\d)/g, '$1.$2');
    rg = rg.replace(/(\d{3})(\d)/g, '$1-$2');

    return rg;
  };

  const onlyNumber = (value: string) => {
    const numeros = value.toString().replace(/\D/g, ''); // Remove todos os caracteres não numéricos
    return numeros;
  };

  const removeSpecialChar = (text) => {
    text = text.toLowerCase();
    text = text.replace(new RegExp('[ÁÀÂÃ]', 'gi'), 'a');
    text = text.replace(new RegExp('[ÉÈÊ]', 'gi'), 'e');
    text = text.replace(new RegExp('[ÍÌÎ]', 'gi'), 'i');
    text = text.replace(new RegExp('[ÓÒÔÕ]', 'gi'), 'o');
    text = text.replace(new RegExp('[ÚÙÛ]', 'gi'), 'u');
    text = text.replace(new RegExp('[Ç]', 'gi'), 'c');

    return text;
  };

  const currencyBrl = (v: number | string) => {
    if (typeof v === 'undefined') {
      return;
    }

    if (typeof v === 'number') {
      v = v.toFixed(2);
      v = v.toString();
    }

    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d)(\d{8})$/, '$1.$2'); //coloca o ponto dos milhões
    v = v.replace(/(\d)(\d{5})$/, '$1.$2'); //coloca o ponto dos milhares
    v = v.replace(/(\d)(\d{2})$/, '$1,$2'); //coloca a virgula antes dos 2 últimos dígitos

    return v.toString();
  };

  function letterNumber(v) {
    if (typeof v === 'number') {
      v = v.toString();
    }

    v = v.replace(/\W/g, ''); //Remove tudo o que não é dígito

    return v;
  }

  function float(v) {
    if (!v) {
      return v;
    }

    v = onlyNumber(v);

    if (typeof v === 'number') {
      v = v.toString();
    }

    v = v.replace(/(\d)(\d{2})$/, '$1.$2'); //coloca a virgula antes dos 2 últimos dígitos

    v = parseFloat(v);

    return v;
  }

  function removeLetters(v) {
    v.replace(/\d|,/g, '');
    return v;
  }

  function capitalize(str) {
    if (typeof str !== 'string') {
      return '';
    }
    return str.charAt(0).toUpperCase() + str.substr(1);
  }

  function lowerCase(str) {
    if (typeof str !== 'string') {
      return '';
    }
    return str.toLowerCase();
  }

  let inputValue = value;

  switch (mask) {
  case 'capitalize':
    inputValue = capitalize(value);
    break;
  case 'lowercase':
    inputValue = lowerCase(value);
    break;
  case 'float':
    inputValue = float(value);
    break;
  case 'retirarletras':
    inputValue = removeLetters(value);
    break;
  case 'cnpj':
    inputValue = cnpj(value);
    break;
  case 'letranumero':
    inputValue = letterNumber(value);
    break;
  case 'cep':
    inputValue = cep(value);
    break;
  case 'data':
    inputValue = data(value);
    break;
  case 'telefone':
    inputValue = phone(value);
    break;
  case 'cpf':
    inputValue = cpf(value);
    break;
  case 'rg':
    inputValue = rg(value);
    break;
  case 'onlyNumber':
    inputValue = onlyNumber(value);
    break;
  case 'removeacento':
    inputValue = removeSpecialChar(value);
    break;
  case 'valor':
    inputValue = currencyBrl(value);
    break;
  }

  return inputValue;
};

export default maskInput;
