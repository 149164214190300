import { OfflineContext } from '@context/OfflineContext';
import useLocalStorage from '@hooks/useLocalStorage';
import { useRouter } from 'next/router';
import { isValidElement, useContext, useEffect } from 'react';
import styled from 'styled-components';
// eslint-disable-next-line max-len
import { SectionInterface } from '@interface/components/Section';
import {
  Box as BoxDefautl,
  Button as ButtonDefault,
  Icon,
  Text,
  TextLink as TextLinkDefault
} from '@src';
import { theme } from '@theme';

const StyledSection = styled.section<{ login: boolean }>`
  display: -webkit-box;
  flex: 1;
  align-items: flex-start;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    display: flex;
    flex-direction: column;
  }
`;

StyledSection.defaultProps = {
};

const Box = styled(BoxDefautl)`
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    width: 100%;
    max-width: initial;
    min-height: 248px;
    &:last-child{
      width: calc(100% - 32px);
      margin: 0 16px;
      z-index: 10;
      padding: 0;
      border-radius: 10px;
      text-align: center;
      justify-content: flex-start;
    }
  }
`;
let isApple = false;

if (typeof navigator !== 'undefined'){
  isApple = /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

const BoxLeft = styled(Box)<{dataSrc: string,
                            login: boolean,
                            isApple: boolean}>`
  object-fit: cover;
  background-image: ${({ dataSrc })=> isApple
    ?  `url(/imgs/${dataSrc}.jpg)`
    : `url(/imgs/${dataSrc}.avif)`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 50vw;
`;

const BoxRight = styled(Box)<{dataSrc: string, login: boolean}>`
  display: flex;
  flex: 1;
  min-width: 47vw;
  flex-direction: column;
  -webkit-box-pack: center;
  justify-content: center;
  padding: 0px 2.5rem;
  background: rgb(255, 251, 247);
`;

const Button = styled(ButtonDefault)`
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    bottom: 20px;
    left: 0;
    right: 0;
    margin: 40px auto 0 auto;
    width: 328px;
    max-width: initial;
    min-width: initial;
    z-index: 10
    ;
  }
`;

const Score = styled.p`
  display: flex;
  flex-direction: column;
  font-family: Lora;
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  line-height: 36px;
  max-width: 401px;
  align-items: flex-start;

  color: #2C3143;
  margin: 0 0 24px 0;
  span {
    display: none;
  }
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    flex-direction: ${({ login })=> login ? 'column' :'column-reverse'};
    font-size: 22px;
    line-height: 28px;
    justify-content: center;
    margin: 0 0 32px 0;
    align-items: center;
    span {
      display: flex;
    }
  }
`;

const ContentText = styled.div`
  margin-bottom: 24px;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    padding: 0;
  }
`;

const Content = styled.div`
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    padding-bottom: 49px;
    overflow-y: auto;
  }
`;

const IconText = styled.div`
  display: flex;
  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    display: block;
  }
`;

const TextLogin = styled(Text)`
  display: flow-root;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #929AB3;
  padding-top: 24px;
`;

const TextLink = styled(TextLinkDefault)`
  font-family: "Plus Jakarta Sans";
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;

  color: #2C3143;
`;

const TextBeforeButton = styled(Text)`
  display: flow-root;
  color: #929AB2;
  margin: 8px 0;
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;

  span {
    color: #929AB2;
    font-size: 18px;
    font-weight: 700;
  }
`;

export const StickyScreening = styled.div<{section:boolean}>`
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 16px;
  margin-top: ${({ section }) => section ? '0;' : '0;' } 
  margin-bottom: 24px;
  z-index:10;

  span {
    color: #FCFCFC;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    padding: 2px 16px;

    background: #2C3143;
    border: 8px solid #FFFBF7;
    border-radius: 20px;
  }

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    display: flex;
    margin-bottom: 10px;
  }
`;

const TextContent = styled(Text)`
  width: 401px;
  font-family: "Plus Jakarta Sans";
  font-weight: 400;
  line-height: 22px !important;
  margin: 0;
  white-space: pre-line;

  @media (max-width: ${({ theme })=>theme.breakpoints.sm}px){
    font-size: 18px !important;
    width: 328px;
    margin: 0 auto;
    white-space: initial;
    line-height: 26px !important;
  }

  b {
    font-weight: 800;
  }
`;

const winmed = typeof window !== 'undefined'
  ? window.matchMedia(`(max-width: ${theme.breakpoints.sm}px)`)
  : {};

const Section = (props: SectionInterface) => {
  const { offline } = useContext(OfflineContext);
  const router = useRouter();
  const [, setInitInfo] = useLocalStorage('initInfo');

  const sectionTheme = props.theme ? props.theme : theme;

  useEffect(()=>{
    setInitInfo(false);
  }, []);

  let content;

  const processContent = (res, index, source) => {

    const content = [];

    if (res.title) {
      let iconTitle;
      if (res.iconTitle) {
        iconTitle = <Icon
          active={true}
          src={res.iconTitle} />;
      }

      content.push(<IconText
        key={index + '__h5__' + source+'__'+res.title}>
        {iconTitle}
        <TextBeforeButton h5>
          {res.title} <span>{res.titleBold}</span>
        </TextBeforeButton>
      </IconText>);
    }

    if (res.text) {
      let iconText;
      if (res.icon) {
        iconText = res.icon;
      }

      content.push(<Text
        key={index + '__p__' + source+'__'+res.text}>
        {iconText} {res.text}
      </Text>);
    }

    return content;
  };

  if (props.content) {
    content = (
      <Content>
        {props.content?.title && !winmed?.['matches'] &&
          <StickyScreening>
            <span>
              {props.content
                ? props.content.title
                : ''}
            </span>
          </StickyScreening>}
        <ContentText>
          {props.content?.titleMobile && winmed?.['matches'] &&
          <StickyScreening section={true}>
            <span>
              {props.content.titleMobile}
            </span>
          </StickyScreening>}

          {props.content.title && !winmed?.['matches'] &&
          <Score>
            {props.content.title} <br/>
            <span>{props.content.subTitle}</span>
          </Score>}

          <TextContent>
            {props.content.text}
          </TextContent>
          <TextContent>
            <b>{props.content.textBold}</b>
          </TextContent>
          {props.content.textBeforeButton.map((res, index) => {
            if (isValidElement(res)){
              return res;
            }

            return (<div
              style={{
                margin: res.margin
              }}
              key={props.img+'__'+index + '__textBeforeButton'}>
              {processContent(res, index, 'textBeforeButton')}
            </div>);
          })}

          {props.content.button &&
          <Button
            data-cy={props.content.button.dataCy}
            theme={sectionTheme}
            styleSheet={{
              marginTop: '24px',
              padding: 0,
              minWidth: '401px'
            }}
            onClick={props.content.button.click}
            label={props.content.button.value} />}
          {props.login &&
          <TextLogin>
            Caso já tenho preenchido o Screening e queira <br/>
            apenas acessar seus resultados,
            <TextLink
              onClick={()=>{
                localStorage.clear();
                if (!offline){
                  props.setLoading(true);
                  props.setOwnerId('');
                  props.setLicense('');
                  props.setShortLinkOtp({});
                  router.push('/otp');
                }
              }}>
                clique aqui
            </TextLink>.
          </TextLogin>}
        </ContentText>

        {props.content.textAfterButtom.map((res, index) => {
          return (<div
            style={{
              margin: res.margin
            }}
            key={props.img+'__'+index + '__textBeforeButton'}>
            {processContent(res, index, 'textAfterButtom')}
          </div>);
        })}
      </Content>
    );
  }

  return (
    <StyledSection
      login={props.login}
      theme={sectionTheme}>
      <BoxLeft
        login={props.login}
        isApple={isApple}
        theme={sectionTheme}
        dataSrc={props.img}
        styleSheet={{
          flexBasis: '50%',
          maxWidth: '50vw'
        }}/>

      <BoxRight
        theme={sectionTheme}
        styleSheet={{
          background: sectionTheme.background.primary['100'],
          flexBasis: '50%'
        }}
        padding={'0 3.5rem'}
        justifyContent={'center'}>
        {content}
      </BoxRight>
    </StyledSection>
  );
};

export default Section;
